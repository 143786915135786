import { Link, useParams } from 'react-router-dom';
import { UserPagePathBuilder } from '../UserPagePathBuilder';

export const GroupConsolePage: React.FC<unknown> = () => {
    const { groupId } = useParams<{ groupId: string }>();

    return (
        <div>
            <Link to={UserPagePathBuilder.groupPage(groupId)}>←グループ画面へ戻る</Link>
            <br />
            グループ管理画面
        </div>
    );
};
