import { NodeEntityOperation } from '../adapter';
import { CommandManager } from '@model-framework/command';
import { StickyNode, NodeName, NodePosition, NodeStyle, NodeCollection } from '../domain';
import { Position } from '@view-model/models/common/types/ui';
import { Point } from '@view-model/models/common/basic';
import { NodeCreateCommand, NodeCollectionCreateCommand, StickyNodeDisplayOrderAddToZoneCommand } from '../command';
import { NodeKey, StickyZoneKey } from '@view-model/domain/key';
import { UserKey } from '@user/domain';
import { FixedCellSizeTextTable, TextElement } from '@view-model/ui/components/Model';
import { ModelLayout } from '@view-model/models/sticky/layout';
import { Timestamp } from '@framework/Timestamp';
import { NodeId, UserId } from '@schema-common/base';
import { DisplayOrderRepository } from '@model-framework/display-order/infrastructure';
import { ViewModelEntity } from '@view-model/domain/view-model';

export class StickyNodeCreationOperation {
    constructor(
        private readonly currentUserId: UserId,
        private readonly nodeEntityOperation: NodeEntityOperation,
        private readonly commandManager: CommandManager,
        private readonly displayOrderRepository: DisplayOrderRepository
    ) {}

    /**
     * 指定の位置に新しいノードを作成する
     * @param position
     * @param style
     * @param name
     * @param url
     */
    createNode(
        viewModel: ViewModelEntity,
        position: Position,
        style: NodeStyle,
        name = '',
        url: string | null = null
    ): NodeId {
        const [nodeCreateCommand, node] = this.buildNodeCreateCommand(viewModel, position, style, name, url);
        this.commandManager.execute(nodeCreateCommand);

        return node.id;
    }

    createNodesFromTable(
        viewModel: ViewModelEntity,
        table: FixedCellSizeTextTable,
        offset: Point,
        style: NodeStyle
    ): NodeCollection {
        const nodes = table.cells().map((cell: TextElement) => cell.toStickyNode(this, offset, style));

        const command = new NodeCollectionCreateCommand(
            viewModel,
            this.nodeEntityOperation,
            this.displayOrderRepository,
            new NodeCollection(nodes)
        );
        this.commandManager.execute(command);

        return new NodeCollection(nodes);
    }

    /**
     * 指定の位置に新しいノードを作成し、指定のゾーンに所属させる
     * @param centerPosition
     * @param style
     * @param zoneKey
     */
    createNodeWithCenterPositionAndAddToZone(
        viewModel: ViewModelEntity,
        centerPosition: Point,
        style: NodeStyle,
        zoneKey: StickyZoneKey
    ): NodeId {
        const position = StickyNode.getPositionFromCenter(centerPosition);
        const node = this.buildNode(position, style);
        const stickyNodeDisplayOrderAddToZoneCommand = new StickyNodeDisplayOrderAddToZoneCommand(
            viewModel,
            node,
            this.nodeEntityOperation,
            zoneKey,
            this.displayOrderRepository
        );

        this.commandManager.execute(stickyNodeDisplayOrderAddToZoneCommand);

        return node.id;
    }

    buildNode(position: Position, style: NodeStyle, name = '', url: string | null = null): StickyNode {
        return new StickyNode({
            key: NodeKey.buildNew(),
            name: new NodeName(name),
            position: NodePosition.load(ModelLayout.snapPosition(position)),
            style,
            url: url,
            createdUserKey: UserKey.buildFromId(this.currentUserId),
            createdAt: Timestamp.now(),
        });
    }

    /**
     * 指定の位置にノードを作成するコマンドと、Nodeを返す
     * @param position
     * @param style
     * @param name
     * @param url
     * @private
     */
    buildNodeCreateCommand(
        viewModel: ViewModelEntity,
        position: Position,
        style: NodeStyle,
        name = '',
        url: string | null = null
    ): [NodeCreateCommand, StickyNode] {
        const node = this.buildNode(position, style, name, url);
        const command = new NodeCreateCommand(viewModel, this.nodeEntityOperation, node, this.displayOrderRepository);

        return [command, node];
    }
}
