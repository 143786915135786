export const KeyboardShortcuts = {
    tab: ['tab'],
    enter: ['enter'],
    selectAll: ['mod+a'],
    shiftKey: ['shift'],
    modKey: ['mod'],
    delete: ['del', 'backspace'],
    undo: ['mod+z'],
    redo: ['mod+shift+z'],
    up: ['up', 'mod+up'],
    down: ['down', 'mod+down'],
    left: ['left', 'mod+left'],
    right: ['right', 'mod+right'],
    escape: ['esc'],
} as const;
