import { ViewId } from '@schema-common/base';
import { toast } from 'react-hot-toast';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { CommandManager } from '@model-framework/command';
import { ConsistencyLinksOperation, StickyModelContentsOperation } from '@view-model/adapter';
import { useRef } from 'react';
import { MultiSelectionMode } from './MultiSelectionMode';
import { useMousetrap } from '@framework/hooks';
import { KeyboardShortcuts } from '@view-model/application/shortcuts';

interface Props {
    selectedViewIds: Set<ViewId>;
    viewModel: ViewModelEntity;
    selectedConsistencyLinkId: string | null;
    commandManagerRef: React.MutableRefObject<CommandManager>;
    setMultiSelectionMode: React.Dispatch<React.SetStateAction<MultiSelectionMode>>;
    stickyModelContentsOperations: Record<string, StickyModelContentsOperation>;
}

// ビューモデルで利用できるショートカットを登録する
export function useViewModelPageContentShortcuts(props: Props): void {
    const propsRef = useRef<Props>(props);
    propsRef.current = props;
    const { selectedViewIds } = props;

    const {
        setMultiSelectionMode,
        stickyModelContentsOperations,
        commandManagerRef,
        selectedConsistencyLinkId,
        viewModel,
    } = props;

    useMousetrap(
        [...KeyboardShortcuts.shiftKey, ...KeyboardShortcuts.modKey],
        () => {
            if (Object.values(stickyModelContentsOperations).some((operation) => operation.hasSelectedElements())) {
                setMultiSelectionMode(MultiSelectionMode.multiElementsSelectionMode);
            } else {
                setMultiSelectionMode(MultiSelectionMode.readyForMultiSelectionMode);
            }
        },
        'keydown'
    );

    useMousetrap(
        [...KeyboardShortcuts.shiftKey, ...KeyboardShortcuts.modKey],
        () => {
            setMultiSelectionMode(MultiSelectionMode.offMode);
        },
        'keyup'
    );

    useMousetrap(
        KeyboardShortcuts.undo,
        async () => {
            const commandManager = commandManagerRef.current;
            if (await commandManager.canUndo()) {
                commandManager.undo();
            } else {
                toast.error("取り消しできませんでした | Couldn't get back");
            }
        },
        'keydown'
    );

    useMousetrap(
        KeyboardShortcuts.redo,
        async () => {
            const commandManager = commandManagerRef.current;
            if (await commandManager.canRedo()) {
                commandManager.redo();
            } else {
                toast.error("やり直しできませんでした | Couldn't redone");
            }
        },
        'keydown'
    );

    useMousetrap(
        KeyboardShortcuts.delete,
        () => {
            // ビューを選択中の場合には、ビューの側でハンドリングしているので、ここでは何もしない
            if (selectedViewIds.size > 0) {
                return;
            }

            // 整合性リンクを選択している場合には、その整合性リンクを削除する
            if (selectedConsistencyLinkId) {
                ConsistencyLinksOperation.handleDelete({
                    id: selectedConsistencyLinkId,
                    viewModelId: viewModel.id,
                    commandManager: commandManagerRef.current,
                }).then();
            }
        },
        'keydown'
    );
}
