import { IPositionSetRepository } from './IPositionSetRepository';
import { PositionSet } from './PositionSet';
import { PositionSetUpdateCommand } from './PositionSetUpdateCommand';
import { ModelLayout } from '@view-model/models/sticky/layout';

export class DragManager {
    private startPositions: PositionSet | null = null;
    private currentPositions: PositionSet | null = null;

    constructor(private readonly repository: IPositionSetRepository) {}

    /**
     * ドラッグ開始時にドラッグ対象の id を指定して呼び出す。
     * @param ids ドラッグ移動先の id 配列
     */
    async dragStart(ids: string[]): Promise<void> {
        if (this.startPositions) {
            // Example issue: https://github.com/levii/balus-app/issues/282
            console.warn(
                'DragManager.dragStart() is called multiple times before dragEnd(). ' +
                    'It may cause an unpredictable problem.'
            );
        }

        const positions = await this.repository.getMany(ids);
        this.startPositions = positions;
        this.currentPositions = positions;
    }

    /**
     * ドラッグ開始時からの差分移動量を受け取って保存する
     */
    async drag(x: number, y: number, dragStartX: number, dragStartY: number): Promise<void> {
        if (!this.startPositions) {
            return;
        }

        this.currentPositions = this.startPositions.moveAll(x - dragStartX, y - dragStartY);
        this.repository.saveMany(this.currentPositions).then();
    }

    /**
     * ドラッグ完了処理
     *
     * @return ドラッグ移動に伴う位置変更コマンドを返す。移動していない場合には null を返す
     */
    async dragEnd(): Promise<PositionSetUpdateCommand | null> {
        if (!this.startPositions || !this.currentPositions) {
            // Example issue: https://github.com/levii/balus-app/issues/282
            console.warn(
                'DragManager.dragEnd() is called multiple times after single dragStart(). ' +
                    'It may cause an unpredictable problem.'
            );
            return null;
        }

        this.currentPositions = this.currentPositions.snapToLayout(ModelLayout);
        this.repository.saveMany(this.currentPositions).then();

        const { startPositions, currentPositions } = this;

        this.startPositions = null;
        this.currentPositions = null;

        // 座標が開始時から変化していなければ、コマンド生成せずに null を返す
        if (startPositions.isEqual(currentPositions)) return null;

        return new PositionSetUpdateCommand(startPositions, currentPositions, this.repository);
    }
}
