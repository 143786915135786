import { useEffect, useRef } from 'react';
import Mousetrap, { ExtendedKeyboardEvent } from 'mousetrap';

type Handlers = Set<(event: ExtendedKeyboardEvent) => void>;

// NOTE: mousetrapだと同じキーバインドに対してコールバックを複数登録できないので、自前で管理する
const handlerMaps = {
    keyup: new Map<string, Handlers>(),
    keydown: new Map<string, Handlers>(),
} as const;

/**
 * Mousetrapを使ってキーボードイベントを監視する
 * @param keys 監視するキーボードイベントのキー。途中で変更しても追随しないので注意
 * @param callback
 * @param action 途中で変更しても追随しないので注意
 */
export function useMousetrap(
    keys: readonly string[],
    callback: (e: ExtendedKeyboardEvent) => void,
    action: 'keydown' | 'keyup'
) {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    const initialKeysRef = useRef(keys);
    const initialActionRef = useRef(action);

    useEffect(() => {
        const initialKeys = [...initialKeysRef.current];
        const initialAction = initialActionRef.current;
        const map = handlerMaps[initialAction];
        const key = initialKeys.join(','); // NOTE: 文字列に変換してキーとして扱う

        const handle = (e: ExtendedKeyboardEvent) => {
            callbackRef.current(e);
        };

        if (!map.has(key)) {
            const handlers: Handlers = new Set();
            map.set(key, handlers);

            Mousetrap.bind(
                initialKeys,
                (e: ExtendedKeyboardEvent) => {
                    handlers.forEach((callback) => callback(e));
                    return false;
                },
                initialAction
            );
        }

        const handlers = map.get(key);
        if (!handlers) {
            throw Error('Invalid state: handlers is undefined');
        }

        handlers.add(handle);

        return () => {
            handlers.delete(handle);
            if (handlers.size === 0) {
                map.delete(key);
                Mousetrap.unbind(initialKeys, initialAction);
            }
        };
    }, []);
}
