import { useCallback, useRef, useState } from 'react';
import { CreatingModelComment } from '../../domain';
import { useTextSelectable } from '@view-model/models/common/hooks';
import { DraggableContainer } from '@view-model/models/common/components/DraggableContainer';
import { ModelCommentDragHandler } from '../ModelCommentDragHandler';
import { ForeignObjectAutosize } from '@view-model/models/common/components/ForeignObjectAutosize';
import { CommentConstants } from '../../constants';
import { ModelCommentHeader } from './ModelCommentHeader';
import { ModelCommentBodyForm } from '../ModelCommentBodyForm';
import { Button } from '@framework/ui/atoms/Button';
import { DragContext } from '@model-framework/ui';

type Props = {
    creatingComment: CreatingModelComment;
    onDrag(dx: number, dy: number): void;
    onDragEnd(): void;
    onSubmit(creatingComment: CreatingModelComment): void;
    onCancel(): void;
};

export const CreatingModelCommentView: React.FC<Props> = ({
    creatingComment,
    onDrag,
    onDragEnd,
    onSubmit,
    onCancel,
}: Props) => {
    const ref = useRef(null);
    // マウスイベントが伝搬することでテキスト操作（選択、リンククリック）ができず、ドラッグによりキャンバス自体が移動してしまうのを止める
    useTextSelectable(ref);
    const [body, setBody] = useState<string>(creatingComment.body);

    const { position, author } = creatingComment;

    const handleDrag = useCallback(
        ({ dx, dy }: DragContext) => {
            onDrag(dx, dy);
        },
        [onDrag]
    );

    const handleDragEnd = useCallback(() => {
        onDragEnd();
    }, [onDragEnd]);

    const handleSubmit = useCallback(() => {
        onSubmit(creatingComment.withBody(body));
    }, [body, creatingComment, onSubmit]);

    const handleCancel = useCallback(() => {
        onCancel();
    }, [onCancel]);

    const { width, headerBarHeight } = CommentConstants.UISetting;

    return (
        <DraggableContainer
            position={position}
            onDrag={handleDrag}
            onDragEnd={handleDragEnd}
            dragHandleContent={<ModelCommentDragHandler width={width} height={headerBarHeight} movable={true} />}
        >
            {/* ドラッグハンドル部分の高さ分だけ y軸方向にオフセットする */}
            <g ref={ref} transform={`translate(0, ${headerBarHeight})`}>
                <ForeignObjectAutosize>
                    <div
                        className="rounded-b-2xl border-x-6 border-b-6 border-t-0 border-white bg-white pb-3 text-3xl"
                        style={{ width: width }}
                    >
                        <ModelCommentHeader author={author} />
                        <ModelCommentBodyForm
                            body={body}
                            onChange={(body) => setBody(body)}
                            onSubmit={handleSubmit}
                            minRows={4}
                        />
                        <div>
                            <Button color="brand" onClick={handleSubmit} disabled={!body}>
                                送信 | Send
                            </Button>
                            <Button color="flat" onClick={handleCancel}>
                                閉じる | Close
                            </Button>
                        </div>
                    </div>
                </ForeignObjectAutosize>
            </g>
        </DraggableContainer>
    );
};
