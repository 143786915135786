import { LinkStyle } from '../../domain';
import { BendingPoint } from './BendingPoint';
import { BezierLine, LineEdgeMarkView, linkColorSelector } from '@model-framework/link';
import { Point } from '@view-model/models/common/basic';
import { BezierLineView } from '@model-framework/link/BezierLine';
import { DragContext } from '@model-framework/ui';

type Props = {
    isSelected: boolean;
    isOtherUserSelected: boolean;
    isRelatedNodeSelected: boolean;
    style: LinkStyle;
    showMenu: boolean;
    bendingPoint: Point;
    bezier: BezierLine;
    onBendingPointDblClick(): void;
    onBendingPointDragStart(): void;
    onBendingPointDrag(context: DragContext): void;
    onBendingPointDragEnd(): void;
    onLineClick(): void;
};

export const LinkLine: React.FC<Props> = ({
    isSelected,
    isOtherUserSelected,
    isRelatedNodeSelected,
    style,
    showMenu,
    bendingPoint,
    bezier,
    onBendingPointDblClick,
    onBendingPointDrag,
    onBendingPointDragStart,
    onBendingPointDragEnd,
    onLineClick,
}: Props) => {
    return (
        <g onClick={onLineClick}>
            <BezierLineView strokeWidth={16} pointerEvents="stroke" visibility="hidden" bezier={bezier} />
            <BezierLineView
                bezier={bezier}
                fill="none"
                stroke={linkColorSelector(style.color, isSelected, isOtherUserSelected, isRelatedNodeSelected)}
                strokeWidth={4}
                strokeLinecap="round"
                pointerEvents="stroke"
                className="group-hover/link:stroke-brand"
                strokeDasharray={style.lineStyle === 'Dashed' ? '12px 18px' : 'none'}
            />
            <LineEdgeMarkView
                markStyle={style.markStyle}
                startEdge={bezier.startEdge()}
                endEdge={bezier.endEdge()}
                linkColor={style.color}
                isSelected={isSelected}
                isOtherUserSelected={isOtherUserSelected}
                isRelatedNodeSelected={isRelatedNodeSelected}
            />
            {showMenu && (
                <g>
                    <BendingPoint
                        position={bendingPoint}
                        onDblClick={onBendingPointDblClick}
                        onDragStart={onBendingPointDragStart}
                        onDrag={onBendingPointDrag}
                        onDragEnd={onBendingPointDragEnd}
                    />
                </g>
            )}
        </g>
    );
};
