import { IStickyModelContentsViewHandlers } from './IStickyModelContentsViewHandlers';
import { Point } from '@view-model/models/common/basic';
import { ApplicationClipboardPayload } from '@view-model/application/clipboard';

/* eslint-disable @typescript-eslint/no-unused-vars */
export class NullHandlers implements IStickyModelContentsViewHandlers {
    handleAllElementsSelect(): void {}
    handleAllElementsDeselect(): void {}
    handleArrowKeys(dx: number, dy: number): void {}
    handleDeleteSelectedElements(): void {}
    handleCutSelectedElements(): void {}
    handleCopySelectedElements(): void {}
    async handlePasteClipboardPayload(payload: ApplicationClipboardPayload, position: Point): Promise<void> {}
    handleDuplicateSelectedElements(position: Point): void {}
}
/* eslint-enable @typescript-eslint/no-unused-vars */
