import { Rect } from '@view-model/models/common/basic';
import { ViewName } from '@view-model/domain/view';
import { ViewId, ViewModelId } from '@schema-common/base';
import { ReadonlyTitleBar } from './ReadonlyTitleBar';
import { EditableTitleBar } from './EditableTitleBar';
import { DragContext } from '@model-framework/ui';

type Props = {
    readonly: boolean;
    viewRect: Rect;
    viewModelId: ViewModelId;
    name: ViewName;
    viewId: ViewId;
    onClick(): void;
    onCopyView(): void;
    onOpenPopupMenu(): void;
    onDragStart(viewId: ViewId): void;
    onDrag(context: DragContext): void;
    onDragEnd(): void;
};

export const ViewTitleBar = ({
    viewModelId,
    readonly,
    viewRect,
    name,
    viewId,
    onClick,
    onCopyView,
    onOpenPopupMenu,
    onDragStart,
    onDrag,
    onDragEnd,
}: Props) => {
    return readonly ? (
        <ReadonlyTitleBar
            viewRect={viewRect}
            name={name}
            viewId={viewId}
            onCopyView={onCopyView}
            onOpenPopupMenu={onOpenPopupMenu}
        />
    ) : (
        <EditableTitleBar
            viewModelId={viewModelId}
            viewRect={viewRect}
            name={name}
            viewId={viewId}
            onClick={onClick}
            onCopyView={onCopyView}
            onOpenPopupMenu={onOpenPopupMenu}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
        />
    );
};
