import { emulatorEnabled, FIREBASE_EMULATOR_UI_URL } from '../../emulatorConfiguration';
import { FirebaseConfig } from '@config/FirebaseConfig';
import { FirebaseConfigUtil } from '@config/FirebaseConfigUtil';

/**
 * 開発者が利用するFirebase RealtimeDatabase コンソールへのURL生成クラス。
 */
export class RTDBConsoleURL {
    static build(path: string): string {
        return emulatorEnabled() ? this.emulatorRTDBConsoleURL(path) : this.cloudRTDBConsoleURL(path);
    }

    private static emulatorRTDBConsoleURL(path: string): string {
        return `${FIREBASE_EMULATOR_UI_URL}/${path}`;
    }

    private static cloudRTDBConsoleURL(path: string): string {
        const { databaseURL, projectId } = FirebaseConfig;
        const instanceName = FirebaseConfigUtil.parseDBNameFromURL(databaseURL);

        const consoleBaseURL = `https://console.firebase.google.com/project/${projectId}/database/${instanceName}/data`;
        return `${consoleBaseURL}/~2F${path.replace('/', '~2F')}`;
    }

    static buildBackupPage(): string {
        const { projectId } = FirebaseConfig;
        return `https://console.firebase.google.com/project/${projectId}/database/backups`;
    }

    static appengineVersionsPage(): string {
        const { projectId } = FirebaseConfig;
        return `https://console.cloud.google.com/appengine/versions?hl=ja&project=${projectId}&serviceId=default`;
    }
}
