import { ViewModelId } from '@schema-common/base';
import { useSetAtom } from 'jotai/react';
import { useSnapshot } from '@framework/hooks';
import { RTDBPath } from '@framework/repository';
import { EditingUserJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-viewer-contents/{viewModelId}/editing-user/{editLockKey}/EditingUserJSON';
import { EditingUser } from '@model-framework/text';
import { editingUsersAtom, EditingUsers } from './editingUsersAtom';

// ノードを編集しているユーザを取得してatomに読み込む
export function EditingUsersLoader({ viewModelId }: { viewModelId: ViewModelId }) {
    const setEditingUsers = useSetAtom(editingUsersAtom);

    useSnapshot({
        path: RTDBPath.ViewModelViewer.editingUsersPath(viewModelId),
        load({ getChildValues }) {
            const values = getChildValues() as Record<string, EditingUserJSON>;
            const editingUsers = Object.entries(values).reduce<EditingUsers>((acc, [key, value]) => {
                if (value) {
                    acc[key] = EditingUser.load(value);
                }
                return acc;
            }, {});
            setEditingUsers(editingUsers);
        },
        stateless: true,
    });

    return null;
}
