import { useEffect, useState } from 'react';
import { useMountedRef } from '@framework/hooks';
import { createGroupEntityRepository, GroupEntity, isGroupEntity } from '@group/domain';
import { GroupId } from '@schema-common/base';
import { DataSnapshot, RefBuilder, RTDBPath } from '@framework/repository';

/**
 * 指定のユーザが所属するグループのID一覧を返す。
 * 未ログインの場合には、所属するグループは存在しないので、空配列を返す。
 *
 * @param userId
 * @returns
 */
const useAssignedGroupIds = (userId: string | null): Set<GroupId> | null => {
    const [ids, setIds] = useState<Set<GroupId> | null>(null);
    const mountedRef = useMountedRef();

    useEffect(() => {
        // 未ログイン時には空のSetをセットして終了
        if (userId === null) {
            setIds(new Set());
            return;
        }

        const ref = RefBuilder.ref(RTDBPath.Group.assignedGroupIndexPath(userId));
        const callback = (snapshot: DataSnapshot) => {
            if (!mountedRef.current) return; // マウント解除済みならば、 state 更新しない
            setIds(new Set(Object.keys(snapshot.val() || {})));
        };
        ref.on('value', callback);
        return () => ref.off('value', callback);
    }, [userId, mountedRef]);
    return ids;
};

/**
 * 指定のユーザが所属するグループ・エンティティの一覧を返す。
 * 一覧を未取得・取得中には null を返し、取得完了後にはエンティティの配列を返す。
 * @param userId
 * @returns
 */
export const useAssignedGroups = (userId: string): GroupEntity[] | null => {
    const ids = useAssignedGroupIds(userId);
    const mountedRef = useMountedRef();
    const [groups, setGroups] = useState<GroupEntity[] | null>(null);
    useEffect(() => {
        if (!ids) {
            setGroups(null);
            return;
        }
        // グループ・エンティティを 1件ずつ state に反映するのではなく、全件取得後にまとめて state に反映する
        Promise.all(Array.from(ids).map((id) => createGroupEntityRepository(id).get())).then((groups) => {
            if (!mountedRef.current) return; // マウント解除済みならば何もしない
            setGroups(groups.filter(isGroupEntity).sort(GroupEntity.compare));
        });
    }, [ids, mountedRef]);

    return groups;
};

/**
 * 指定のユーザが複数のグループに所属するか否かを返す。
 * @param userId
 * @returns
 */
export const useAssignedToManyGroups = (userId: string): boolean => {
    const ids = useAssignedGroupIds(userId);
    return ids ? ids.size > 1 : false;
};
