import { useSnapshot } from '@framework/hooks';
import { RTDBPath } from '@framework/repository';
import { ViewId, ViewModelId } from '@schema-common/base';
import { ViewName } from '@view-model/domain/view';
import { useOnEnterCallback } from '@view-model/models/common/hooks';
import { useViewOperation } from '@view-model/ui/components/View/ViewContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';
import { viewTitleBarAtom } from '../viewTitlaBarAtom';

type Props = {
    viewModelId: ViewModelId;
    viewId: ViewId;
    isEditing: boolean;
    name: ViewName;
    onNameEndEdit(): void;
};

export const EditableViewTitle: React.FC<Props> = ({ viewModelId, viewId, isEditing, name, onNameEndEdit }: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const oldNameRef = useRef(name); // 編集完了時にUndoするための値を保持する
    const newNameRef = useRef(name); // 編集完了時のonBlurコールバックがviewName state に依存しないようにするためstateとは別にRefで値を保持する
    const viewAdapter = useViewOperation();
    const [viewName, setViewName] = useState(name);

    useOnEnterCallback(inputRef);

    useEffect(() => {
        if (isEditing) inputRef.current?.focus();
    }, [isEditing]);

    // テキスト編集中にテキスト選択できるようにイベント伝搬を止める
    // ReactコンポーネントのonMouseDown()イベントが飛んでこないため直接リスナー登録する（D3Dragでドラッグするせい？）
    useEffect(() => {
        const current = inputRef.current;
        if (!current) return;

        const stopPropagation = (event: MouseEvent) => event.stopPropagation();
        current.addEventListener('mousedown', stopPropagation);

        return () => {
            current.removeEventListener('mousedown', stopPropagation);
        };
    }, []);

    // Undo/Redoなどデータベース経由での状態変更を反映
    useSnapshot({
        path: RTDBPath.View.namePath(viewModelId, viewId),
        load({ snapshot }) {
            const value = snapshot.val() as string | null;
            setViewName(new ViewName(value || ''));
        },
    });

    const onFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        // 編集開始時にテキスト最後の位置にカーソルを合わせる
        const t = event.target;
        const len = t.value.length;
        t.setSelectionRange(len, len);

        newNameRef.current = oldNameRef.current = new ViewName(t.value);
    }, []);

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            // RTDB に保存するより先に、 React state に値を設定する.
            // (こうしておかないと、日本語入力中に１文字ずつ入力確定するような動きとなってしまう)
            const newViewName = new ViewName(event.target.value);
            setViewName(newViewName);

            newNameRef.current = newViewName;
            viewAdapter.handleEditingNameChanged(newViewName).then();
        },
        [viewAdapter]
    );

    const onBlur = useCallback(() => {
        if (oldNameRef.current && newNameRef.current) {
            viewAdapter.handleEditingNameConfirmed(newNameRef.current, oldNameRef.current).then();
            onNameEndEdit();
        }
    }, [onNameEndEdit, viewAdapter]);

    const {
        title: { fontSize },
    } = useAtomValue(viewTitleBarAtom);

    return (
        <>
            <input
                className="pointer-events-auto w-full grow border-none px-8 py-0 text-center align-middle font-bold leading-none outline-none"
                style={{
                    ...(isEditing ? {} : { display: 'none' }),
                    fontSize: fontSize,
                    height: fontSize * 1.3,
                }}
                type={'text'}
                placeholder={'タイトル | Title'}
                value={viewName.value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                ref={inputRef}
            />
            <div
                className="pointer-events-none w-full grow truncate text-center font-bold leading-tight text-white"
                style={{
                    ...(isEditing ? { display: 'none' } : {}),
                    fontSize: fontSize,
                }}
            >
                {viewName.value ? viewName.value : 'タイトル | Title'}
            </div>
        </>
    );
};
