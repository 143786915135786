import { DragContext, DraggableSVGGElement } from '@view-model/models/framework/ui';
import { LineEdge } from '@view-model/models/framework/link';
import { DEFAULT_ICON_CIRCLE_SIZE } from '@view-model/models/common/components/LinkCreator/LinkCreator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Position } from '@view-model/models/common/types/ui';

type ButtonProps = {
    position: Position;
    angle: number;
    onDragStart: () => void;
    onDrag: (context: DragContext) => void;
    onDragEnd: () => void;
};

const DragButton: React.FC<ButtonProps> = ({ position, angle, onDragStart, onDrag, onDragEnd }: ButtonProps) => {
    return (
        <DraggableSVGGElement
            position={position}
            onDragMoveStarted={onDragStart}
            onDrag={onDrag}
            onDragMoveEnded={onDragEnd}
        >
            <circle className="fill-white stroke-gray-600" r={DEFAULT_ICON_CIRCLE_SIZE} cx={0} cy={0} strokeWidth={1} />
            <foreignObject
                className="cursor-pointer text-center align-middle"
                width={DEFAULT_ICON_CIRCLE_SIZE * 2}
                height={DEFAULT_ICON_CIRCLE_SIZE * 2}
                fontSize={DEFAULT_ICON_CIRCLE_SIZE}
                transform={`translate(${-DEFAULT_ICON_CIRCLE_SIZE}, ${-DEFAULT_ICON_CIRCLE_SIZE})`}
                onClick={onDragStart}
                style={{ lineHeight: `${DEFAULT_ICON_CIRCLE_SIZE * 2}px` }}
            >
                <FontAwesomeIcon icon={faArrowUp} transform={{ rotate: angle + 90 }} />
            </foreignObject>
        </DraggableSVGGElement>
    );
};

type Props = {
    SourceLineEdge: LineEdge;
    TargetLineEdge: LineEdge;
    onReplaceSourceStart: () => void;
    onReplaceSourceMove: (context: DragContext) => void;
    onReplaceSourceEnd: () => void;
    onReplaceTargetStart: () => void;
    onReplaceTargetMove: (context: DragContext) => void;
    onReplaceTargetEnd: () => void;
};

export const LinkDragHandle: React.FC<Props> = ({
    SourceLineEdge,
    TargetLineEdge,
    onReplaceSourceStart,
    onReplaceSourceMove,
    onReplaceSourceEnd,
    onReplaceTargetStart,
    onReplaceTargetMove,
    onReplaceTargetEnd,
}: Props) => {
    return (
        <>
            <DragButton
                position={SourceLineEdge.position}
                angle={SourceLineEdge.markAngle.angle}
                onDragStart={onReplaceSourceStart}
                onDrag={onReplaceSourceMove}
                onDragEnd={onReplaceSourceEnd}
            />
            <DragButton
                position={TargetLineEdge.position}
                angle={TargetLineEdge.markAngle.angle}
                onDragStart={onReplaceTargetStart}
                onDrag={onReplaceTargetMove}
                onDragEnd={onReplaceTargetEnd}
            />
        </>
    );
};
