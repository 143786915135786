import { Random } from '@framework/Random';
import { Timestamp } from '@framework/Timestamp';
import { GroupKey } from '@group/domain/key';
import { WorkspaceJSON, WorkspaceJSONOnWrite } from '@schema-app/workspaces/{workspaceKey}/WorkspaceJSON';
import { GroupId, UserId } from '@schema-common/base';
import { UserKey } from '@user/domain';
import { WorkspaceKey } from '@workspace/domain/key';
import { WorkspaceDescription } from './vo/WorkspaceDescription';
import { WorkspaceMemberRole } from './vo/WorkspaceMemberRole';
import { WorkspaceMemberRoles } from './vo/WorkspaceMemberRoles';
import { WorkspaceName } from './vo/WorkspaceName';
import { WorkspaceSetting } from './vo/WorkspaceSetting';
import { isEqual } from 'lodash';

export class Workspace {
    constructor(
        public readonly key: WorkspaceKey,
        public readonly name: WorkspaceName,
        public readonly description: WorkspaceDescription,
        public readonly ownerGroupKey: GroupKey,
        public readonly memberRoles: WorkspaceMemberRoles,
        public readonly createdAt: Timestamp,
        public readonly updatedAt: Timestamp,
        public readonly setting: WorkspaceSetting,
        public readonly trashedAt: Timestamp | null,
        public readonly trashedBy: UserId | null,
        public readonly imageUrl: string | null,
        public readonly isPersonal: boolean
    ) {}

    static buildNew(name: string, groupId: string, userId: string, timestamp: Timestamp): Workspace {
        const id = Random.generateRandomID(20);
        const key = WorkspaceKey.buildFromID(id);

        return new this(
            key,
            new WorkspaceName(name),
            new WorkspaceDescription(''),
            GroupKey.buildFromID(groupId),
            WorkspaceMemberRoles.fromEntities([[UserKey.buildFromId(userId).toString(), 'admin']]),
            timestamp,
            timestamp,
            WorkspaceSetting.buildDefault(),
            null,
            null,
            null,
            false
        );
    }

    static buildNewPersonal(groupId: GroupId, userId: UserId, timestamp: Timestamp): Workspace {
        const key = WorkspaceKey.buildFromID(`${groupId}-${userId}`);

        return new this(
            key,
            new WorkspaceName('パーソナルワークスペース'),
            new WorkspaceDescription(''),
            GroupKey.buildFromID(groupId),
            WorkspaceMemberRoles.fromEntities([[UserKey.buildFromId(userId).toString(), 'editor']]),
            timestamp,
            timestamp,
            WorkspaceSetting.buildDefault(),
            null,
            null,
            null,
            true
        );
    }

    get id(): string {
        return this.key.workspaceID;
    }

    public get isPublicSpace(): boolean {
        return this.setting.isPublicSpace;
    }

    get ownerGroupId(): string {
        return `${this.ownerGroupKey.id}`;
    }

    get isShared(): boolean {
        return !this.isPersonal;
    }

    getMemberRoleOf(userId: UserId): WorkspaceMemberRole | null {
        return this.memberRoles.getRoleOf(userId);
    }

    dump(): WorkspaceJSONOnWrite {
        return {
            id: this.key.id,
            groupId: this.ownerGroupId,
            key: null, // TODO: EPIC-278 削除予定
            ownerGroupKey: null, // TODO: EPIC-278 削除予定
            name: this.name.toString(),
            description: this.description.toString(),
            members: this.memberRoles.dump(),
            createdAt: this.createdAt.valueOf(),
            updatedAt: this.updatedAt.valueOf(),
            setting: this.setting.dump(),
            trashedAt: this.trashedAt?.valueOf() || null,
            trashedBy: this.trashedBy || null,
            imageUrl: this.imageUrl || null,
            isPersonal: this.isPersonal || false,
        };
    }

    static load(dump: WorkspaceJSON): Workspace {
        const workspaceKey = WorkspaceKey.buildFromID(dump.id);

        return new Workspace(
            workspaceKey,
            new WorkspaceName(dump.name),
            new WorkspaceDescription(dump.description || ''),
            GroupKey.buildFromID(dump.groupId),
            WorkspaceMemberRoles.load(dump.members),
            new Timestamp(dump.createdAt),
            new Timestamp(dump.updatedAt),
            WorkspaceSetting.load(dump.setting),
            dump.trashedAt ? new Timestamp(dump.trashedAt) : null,
            dump?.trashedBy || null,
            dump.imageUrl || null,
            dump.isPersonal || false
        );
    }

    static compare(a: Workspace, b: Workspace): number {
        return a.name.localeCompare(b.name);
    }

    public isEqual(other: Workspace): boolean {
        return isEqual(this.dump(), other.dump());
    }

    public matchQuery(query: string): boolean {
        const keywords = query.split(' ');
        return this.name.includesMulti(keywords);
    }

    userIds(): string[] {
        return this.memberRoles.userIds();
    }
}

export const isWorkspace = (ws: Workspace | null): ws is Workspace => ws instanceof Workspace;
