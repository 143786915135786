import { Dispatch, useEffect, useLayoutEffect, useReducer, useRef } from 'react';
import { useCurrentUserId } from '@framework/auth';
import { createWorkspaceEntityRepository, WorkspaceEntityRepository, Workspace } from '@workspace/domain/workspace';
import { FolderId, ViewModelId, WorkspaceId, WorkspaceKeyString } from '@schema-common/base';
import { ObjectRepository, RTDBPath } from '@framework/repository';
import { SidebarTreeStore, SidebarTreeAction, sidebarTreeReducer } from './SidebarTreeStore';
import { RootFolderTreeRepository } from '@workspace/view-model-folder/infrastructure/FolderTree';
import { ViewModelJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-models/{viewModelId}/ViewModelJSON';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { useParams } from 'react-router-dom';
import { RouteParams } from '@user/pages/RouteParams';
import { FolderJSON } from '@schema-app/workspace-contents/{workspaceKey}/folders/{folderId}/FolderJSON';
import { Folder } from '@workspace/view-model-folder/domain/Folder';
import { RootFolderTree } from '@workspace/view-model-folder/domain/FolderTree';
import { KeysRepository } from '@framework/repository/Collection';
import { useActionLogSender } from '@framework/action-log';
import { usePrevious } from '@view-model/models/common/hooks/usePrevious';

const initialStore = {
    firstLoaded: false,
    all: {
        workspaces: {},
        folderTrees: {},
        folders: {},
        viewModels: {},
    },
    searchResult: null,
    open: {
        workspaceIds: {},
        folderIds: {},
    },
    control: {
        lastOpenedWorkspaceId: '',
        lastOpenedViewModelId: '',
        scrolledWorkspaceId: '',
        scrolledViewModelId: '',
    },
};

export const useSidebarTree = (
    groupId: string
): SidebarTreeStore & {
    dispatch: Dispatch<SidebarTreeAction>;
} => {
    const currentUserId = useCurrentUserId();
    const [store, dispatch] = useReducer(sidebarTreeReducer, initialStore);
    const { workspaceId: workspaceIdParam, viewModelId: viewModelIdParam } = useParams<RouteParams>();

    const actionLogSender = useActionLogSender();

    const workspaceIdsRepoRef = useRef<KeysRepository<WorkspaceKeyString> | null>(null);
    const workspaceEntityReposRef = useRef<Record<WorkspaceId, WorkspaceEntityRepository>>({});
    const folderTreeReposRef = useRef<Record<WorkspaceId, RootFolderTreeRepository>>({});
    const folderReposRef = useRef<Record<WorkspaceId, ObjectRepository<FolderJSON, Folder>>>({});
    const viewModelReposRef = useRef<Record<WorkspaceId, ObjectRepository<ViewModelJSON, ViewModelEntity>>>({});

    // グループ内の別のワークスペースページビューモデルページに遷移時やURLに直接アクセスした時などに、
    // サイドバー上でそのワークスペースを展開するためのuseEffect
    // フォルダ内のビューモデルにジャンプした時はuseSidebarWorkspace内でフォルダーを展開するuseEffectがある
    useEffect(() => {
        if (!currentUserId) return;

        // lastOpenedWorkspaceIdとworkspaceIdParamを比較することで、ワークスペースページを表示した際に
        // サイドバーの該当ワークスペースが展開されっぱなしになるのを防ぐ
        if (workspaceIdParam && workspaceIdParam !== store.control.lastOpenedWorkspaceId) {
            dispatch({
                type: 'OPEN_WORKSPACE',
                payload: { workspaceId: workspaceIdParam, lastOpenedWorkspaceId: workspaceIdParam },
            });

            return;
        }

        // lastOpenedViewModelIdとviewModelIdParamを比較することで、ワークスペースページを表示した際に
        // サイドバーの該当ワークスペースが展開されっぱなしになるのを防ぐ
        if (viewModelIdParam && viewModelIdParam !== store.control.lastOpenedViewModelId) {
            const result = Object.entries(store.all.folderTrees).find(([, folderTree]) =>
                folderTree.hasDescendantViewModel(viewModelIdParam)
            );

            if (!result) return;
            const [workspaceId] = result;

            dispatch({
                type: 'OPEN_WORKSPACE',
                payload: {
                    workspaceId,
                    lastOpenedWorkspaceId: workspaceId,
                    lastOpenedViewModelId: viewModelIdParam,
                },
            });
        }
    }, [
        workspaceIdParam,
        viewModelIdParam,
        store.all.folderTrees,
        currentUserId,
        store.control.lastOpenedWorkspaceId,
        store.control.lastOpenedViewModelId,
        dispatch,
    ]);

    // ページ遷移時にスクロールするべきワークスペースやビューモデルを指定するuseLayoutEffect
    // useEffectだと対象のビューモデルへスクロール後に他のビューモデルが描画されてしまい、
    // 結果的に対象のビューモデルが画面外に押し出されてしまう
    // そのため、useEffectの代わりにuseLayoutEffectを使用している
    useLayoutEffect(() => {
        if (workspaceIdParam) {
            dispatch({
                type: 'SET_SCROLLED_WORKSPACE',
                payload: {
                    workspaceId: workspaceIdParam,
                },
            });

            return;
        }
        if (viewModelIdParam) {
            dispatch({
                type: 'SET_SCROLLED_VIEW_MODEL',
                payload: {
                    viewModelId: viewModelIdParam,
                },
            });
        }
    }, [workspaceIdParam, viewModelIdParam, dispatch]);

    useEffect(() => {
        if (!currentUserId) return;
        if (!groupId) return;

        const workspaceEntityRepos = workspaceEntityReposRef.current;
        const folderTreeRepos = folderTreeReposRef.current;
        const folderRepos = folderReposRef.current;
        const viewModelRepos = viewModelReposRef.current;

        if (!workspaceIdsRepoRef.current) {
            workspaceIdsRepoRef.current = new KeysRepository<WorkspaceKeyString>(
                RTDBPath.Workspace.assignedGroupWorkspaceIndexPath(currentUserId, groupId)
            );
        }
        const workspaceIdsRepo = workspaceIdsRepoRef.current;

        // 初回のみワークスペースをまとめて取得する
        if (!store.firstLoaded) {
            // 初回読込時のみワークスペースをまとめて取得するための関数
            const fetchFirstLoad = async (): Promise<{
                workspaces: Record<WorkspaceId, Workspace>;
                folderTrees: Record<WorkspaceId, RootFolderTree>;
                folders: Record<FolderId, Folder>;
                viewModels: Record<ViewModelId, ViewModelEntity>;
            }> => {
                const workspaceIds = await workspaceIdsRepo.get();

                const workspaces: Workspace[] = (
                    await Promise.all(
                        workspaceIds.map(async (id) => {
                            const repo = createWorkspaceEntityRepository(id);
                            return repo.get();
                        })
                    )
                ).filter((w): w is Workspace => !!w);

                const folderTrees: Record<WorkspaceId, RootFolderTree> = Object.fromEntries(
                    await Promise.all(
                        workspaces.map(async (w) => {
                            const repo = new RootFolderTreeRepository(w.id);
                            folderTreeRepos[w.id] = repo;
                            const rootFolderTree = await repo.load();
                            return [w.id, rootFolderTree];
                        })
                    )
                );

                // TODO: EPIC-278 フォルダの永続パス中に workspaceId が含まれなくなったら、 folderIds を FolderId[] 型に変更する
                const folderIds: { workspaceId: WorkspaceId; folderId: FolderId }[] = [];
                Object.entries(folderTrees).forEach(([workspaceId, folderTree]) => {
                    folderTree.getAllFolderIds().forEach((folderId) => {
                        folderIds.push({ workspaceId, folderId });
                    });
                });

                const folders: Record<FolderId, Folder> = Object.fromEntries(
                    await Promise.all(
                        folderIds.map(async ({ folderId }) => {
                            const repo = new ObjectRepository(Folder, RTDBPath.Workspace.folderPath(folderId));
                            folderRepos[folderId] = repo;
                            return [folderId, await repo.get()];
                        })
                    )
                );

                const viewModelIds: ViewModelId[] = [];
                Object.values(folderTrees).forEach((folderTree) => {
                    viewModelIds.push(...folderTree.getAllViewModelIds());
                });

                const viewModels: Record<ViewModelId, ViewModelEntity> = Object.fromEntries(
                    await Promise.all(
                        viewModelIds.map(async (viewModelId) => {
                            const repo = new ObjectRepository(
                                ViewModelEntity,
                                RTDBPath.ViewModel.viewModelPath(viewModelId)
                            );
                            viewModelRepos[viewModelId] = repo;
                            return [viewModelId, await repo.get()];
                        })
                    )
                );

                return {
                    workspaces: Object.fromEntries(workspaces.map((ws) => [ws.id, ws])),
                    folderTrees,
                    folders,
                    viewModels,
                };
            };

            const start = performance.now();

            fetchFirstLoad().then((values) => {
                dispatch({
                    type: 'FIRST_LOAD',
                    payload: values,
                });

                const end = performance.now();

                actionLogSender('global:sidebar:first_loaded', {
                    groupId,
                    processingTimeMilliseconds: end - start,
                    workspaceCount: Object.keys(values.workspaces).length,
                    folderCount: Object.keys(values.folders).length,
                    viewModelCount: Object.keys(values.viewModels).length,
                });
            });
            return;
        } else {
            workspaceIdsRepo.addListener(
                // 所属ワークスペースが増えた時
                (workspaceId) => {
                    // ワークスペースの監視
                    if (!workspaceEntityRepos[workspaceId]) {
                        workspaceEntityRepos[workspaceId] = createWorkspaceEntityRepository(workspaceId);
                    }
                    workspaceEntityRepos[workspaceId].addListener((workspace) => {
                        if (!workspace) return;
                        dispatch({
                            type: 'ADD_WORKSPACE',
                            payload: {
                                workspace,
                            },
                        });
                    });

                    // フォルダツリーの監視
                    if (!folderTreeRepos[workspaceId]) {
                        folderTreeRepos[workspaceId] = new RootFolderTreeRepository(workspaceId);
                    }
                    folderTreeRepos[workspaceId].on((rootFolderTree) => {
                        dispatch({
                            type: 'ADD_ROOT_FOLDER_TREE',
                            payload: {
                                workspaceId,
                                rootFolderTree,
                            },
                        });
                    });
                },
                // 所属ワークスペースが減った時
                (workspaceId) => {
                    // ワークスペースの監視解除
                    workspaceEntityRepos[workspaceId]?.removeListener();
                    delete workspaceEntityRepos[workspaceId];

                    // フォルダツリーの監視解除
                    folderTreeRepos[workspaceId]?.off();
                    delete folderTreeRepos[workspaceId];

                    // 削除されたワークスペースに紐づく各種データをstoreから削除
                    dispatch({
                        type: 'REMOVE_BULK',
                        payload: {
                            workspaceId,
                        },
                    });
                }
            );

            return () => {
                Object.values(workspaceEntityRepos).forEach((repo) => repo.removeListener());
                Object.values(folderTreeRepos).forEach((repo) => repo.off());
                Object.values(folderRepos).forEach((repo) => repo.removeListener());
                Object.values(viewModelRepos).forEach((repo) => repo.removeListener());
            };
        }
    }, [store.firstLoaded, groupId, currentUserId, dispatch, actionLogSender]);

    const previousFolderTrees = usePrevious(store.all.folderTrees);

    useEffect(() => {
        // RootFolderTreeのレコードから、全てのフォルダーIDの配列を生成
        const treeToIds = (folderTrees: Record<WorkspaceId, RootFolderTree>): FolderId[] => {
            const ids: FolderId[] = [];
            Object.values(folderTrees).forEach((folderTree) => {
                ids.push(...folderTree.getAllFolderIds());
            });
            return ids;
        };

        const previousIds = treeToIds(previousFolderTrees || {});
        const currentIds = treeToIds(store.all.folderTrees);

        const addedIds = currentIds.filter((id) => !previousIds.includes(id));
        const removedIds = previousIds.filter((id) => !currentIds.includes(id));

        addedIds.forEach((id) => {
            const repo = new ObjectRepository(Folder, RTDBPath.Workspace.folderPath(id));
            folderReposRef.current[id] = repo;
            repo.addListener((folder) => {
                if (folder) {
                    dispatch({
                        type: 'SET_FOLDER',
                        payload: {
                            folder,
                        },
                    });
                } else {
                    dispatch({
                        type: 'REMOVE_FOLDER',
                        payload: {
                            folderId: id,
                        },
                    });
                }
            });
        });

        removedIds.forEach((id) => {
            folderReposRef.current[id]?.removeListener();
            delete folderReposRef.current[id];
            dispatch({
                type: 'REMOVE_FOLDER',
                payload: {
                    folderId: id,
                },
            });
        });
    }, [store.all.folderTrees, previousFolderTrees]);

    useEffect(() => {
        // RootFolderTreeのレコードから、全てのビューモデルIDの配列を生成
        const treeToIds = (folderTrees: Record<WorkspaceId, RootFolderTree>): ViewModelId[] => {
            const ids: ViewModelId[] = [];
            Object.values(folderTrees).forEach((folderTree) => {
                ids.push(...folderTree.getAllViewModelIds());
            });
            return ids;
        };

        const previousIds = treeToIds(previousFolderTrees || {});
        const currentIds = treeToIds(store.all.folderTrees);

        const addedIds = currentIds.filter((id) => !previousIds.includes(id));
        const removedIds = previousIds.filter((id) => !currentIds.includes(id));

        addedIds.forEach((id) => {
            const repo = new ObjectRepository(ViewModelEntity, RTDBPath.ViewModel.viewModelPath(id));
            viewModelReposRef.current[id] = repo;
            repo.addListener((viewModel) => {
                if (viewModel) {
                    dispatch({
                        type: 'SET_VIEW_MODEL',
                        payload: {
                            viewModel,
                        },
                    });
                } else {
                    dispatch({
                        type: 'REMOVE_VIEW_MODEL',
                        payload: {
                            viewModelId: id,
                        },
                    });
                }
            });
        });

        removedIds.forEach((id) => {
            viewModelReposRef.current[id]?.removeListener();
            delete viewModelReposRef.current[id];
            dispatch({
                type: 'REMOVE_VIEW_MODEL',
                payload: {
                    viewModelId: id,
                },
            });
        });
    }, [store.all.folderTrees, previousFolderTrees]);

    return { ...store, dispatch };
};
